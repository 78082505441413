export default [
  { value: "None", key: 0 },
  { value: "FreeSpins", key: 1 },
  { value: "Wild", key: 2 },
  { value: "Medusa", key: 4 },
  { value: "Lion", key: 8 },
  { value: "Bull", key: 16 },
  { value: "Eagle", key: 32 },
  { value: "Delta", key: 64 },
  { value: "K", key: 128 },
  { value: "Q", key: 256 },
  { value: "J", key: 512 },
  { value: "Candy", key: 1024 },
  { value: "Ten", key: 2048 },
  { value: "Nine", key: 4096 },
  { value: "Minor", key: 8192 },
  { value: "Major", key: 16384 },
  { value: "Grand", key: 32768 },
  { value: "Bet", key: 65536 },
  { value: "Scatter", key: 131072 },
  { value: "Jackpot", key: 262144 },
  { value: "Multiplier", key: 524288 },
];
