<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs12 md12>
        <v-text-field
          v-model="detail.i"
          label="Id"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.n"
          label="Name"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.b"
          label="Bundle Id"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.g"
          label="Group Id"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.o"
          label="Order"
          :counter="max"
          required
        ></v-text-field>
        <v-switch
          v-model="detail.ie"
          :label="`IsEnable`"
          hide-details
        ></v-switch>
        <v-text-field
          v-model="detail.ml"
          type="number"
          label="MinLevel"
          :counter="max"
          required
        ></v-text-field>
        <v-autocomplete
          v-model="detail.m"
          label="Mini Game Type"
          :counter="max"
          :items="miniGameType"
          item-value="key"
          item-text="value"
          placeholder="Select..."
        ></v-autocomplete>
        <v-autocomplete
          v-model="detail.fs"
          label="Free Spin Symbol"
          :counter="max"
          :items="freeSpinSymbol"
          item-value="key"
          item-text="value"
          placeholder="Select..."
        ></v-autocomplete>
        <v-text-field
          v-model="detail.mf"
          type="number"
          label="Min Free Spin Count"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.mg"
          type="number"
          label="Min Grand Jackpot Bet"
          :counter="max"
          required
        ></v-text-field>
        <v-autocomplete
          v-model="detail.pt"
          label="Priority Type"
          :counter="max"
          :items="priorityType"
          item-value="key"
          item-text="value"
          placeholder="Select..."
        ></v-autocomplete>
  
      </v-flex>
      <v-btn color="success" @click="save">Save</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import priorityType from "@/lib/priorityType";
import freeSpinSymbol from "@/lib/symbol";
import miniGameType from "@/lib/miniGameType";
import router from "@/router";
export default {
  data: () => ({
    detail: {},
    priorityType: priorityType,
    freeSpinSymbol: freeSpinSymbol,
    miniGameType: miniGameType,
    alert: false,
    max: 120,
  }),

  methods: {
    async save() {
      await this.$store.dispatch("gameModes/post", this.detail);
      router.push("/gameModes");
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  computed: {},
  async created() {
    if (this.$route.params.id) {
      await this.$store.dispatch("gameModes/getById", this.$route.params.id);

      this.detail = this.$store.state.gameModes.detail;
    }
  },
};
</script>
