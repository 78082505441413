export default [
  { value: "WheelFortune", key: 1 },
  { value: "Treasure", key: 2 },
  { value: "Daruma", key: 3 },
  { value: "CoinFlip", key: 4 },
  { value: "SuperVegasWheel", key: 5 },
  { value: "MultiplierWheel", key: 6 },
  { value: "MultiplierCard", key: 7 },
  { value: "IceDaruma", key: 8 },
  { value: "LuckyPickClaim", key: 9 },
  { value: "LuckyPick", key: 10 },
  { value: "WinxClub", key: 11 },
  { value: "RoyalWheel", key: 12 },
];
